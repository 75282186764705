

  export default {
    name: 'PageAbout',

    props: {
    },

    data() {
      return {
        about: "As real laboratories, adapted to our respective geographical, climatic and social context, we are united by the concept of a non-extractive lifestyle. As a way of life and a demand towards politics and economy. RuralMindshift, as a decentralized database for non-profit projects in rural areas helps us to secure, develop and share our knowledge transnationally. Motivating others to see rural areas as a space of opportunity, it is also a tool for self-assurance, motivation and solidarity among initiatives, with a clear commitment to ecological sufficiency."
      }
    },

    mounted() {

    },

    methods: {
    }
  }

